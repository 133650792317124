<!--
 * @Description: 
 * @Author: weig
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: weig
 * @LastEditTime: 2021-12-20 18:00:04
-->
<template>
<div></div>
</template>
<script>
import { reactive, onMounted,onBeforeMount } from "vue";
export default {
  setup() {
    onBeforeMount(()=>{
       window.location.href = "" + process.env.VUE_APP_LOGIN_URL + "/account/login?returnurl=" + encodeURIComponent(window.location.href);
    })
    return {}
  }
};
</script>